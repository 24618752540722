import { useEffect, useRef } from 'react';
import { toast } from '@/layouts/components/ToastMessage';

export default function UpDateI18Xls() {
  // NO-TRANSLATION
  const uploadRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    uploadRef.current?.addEventListener('change', onChange as any);
    return () => {
      uploadRef.current?.removeEventListener('change', onChange as any);
    };
  }, []);

  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
    try {
      // 限制文件类型
      if (!file.name.endsWith('.xls')) {
        throw new Error('仅支持 xls 文件');
      }

      const formData = new FormData();
      formData.append('file', file);

      const response = await fetch('/api/update-i18n-xls', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || '上传失败');
      }

      toast.success('语言文件更新成功！');
    } catch (err) {
      toast.error(err instanceof Error ? err.message : '未知错误');
    } finally {
      event.target.value = ''; // 清空选择器
    }
  }

  return (
    <div>
      <input type="file" className="hidden" accept={'.xls'} ref={uploadRef} />
      <button type="button" className="mainColorButton !bg-primary !h-[40px] !p-0" onClick={() => {
        uploadRef.current?.click();
      }}>导入覆盖本地语言.xls文件</button>
    </div>
  )
}