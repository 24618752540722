import { BasilSearchSolid } from '@/assets/icons/comm/BasilSearchSolid';
import { LoadingSvg } from '@/assets/icons/comm/LoadingSvg';
import BaseInput from '@/components/base/baseInput';
import BaseTopNav from '@/components/base/baseTopNav';
import { toast } from '@/layouts/components/ToastMessage';
import { defaultLocale } from '@/locales';
import deTranslation from '@/locales/de.json';
import { default as en, default as enTranslation } from '@/locales/en.json';
import esTranslation from '@/locales/es.json';
import frTranslation from '@/locales/fr.json';
import hiTranslation from '@/locales/hi.json';
import itTranslation from '@/locales/it.json';
import jp from '@/locales/ja.json';
import zh from '@/locales/zh-TW.json';
import { BasePost, useReq } from '@/services/net/request';

import { devList, httpPort, imSocketPort, socketPort } from '@/enums/netEnum';
import { FormatUtils } from '@/utils/format';
import { Button, Select, SelectItem, Switch } from '@nextui-org/react';
import { history, useModel } from '@umijs/max';
import { useMount } from 'ahooks';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { exportExcel } from '@/utils';
import { getLangJsonApi } from '@/services/api/requestApi';
import CacheEnum from '@/enums/cacheEnum';
import UpDateI18Json from '@/pages/public/LanguageKeyPage/components/UpDateI18Json';
import UpDateI18Xls from '@/pages/public/LanguageKeyPage/components/UpDateI18Xls';

/**
 *
 * @returns
 *  -------这个页面 是工具页面，仅开发人员使用-------
 *  显示 国际化原来本 key
 *  搜索国际化翻译
 *  发送当前 json 到后端
 *
 */

async function i18nImportApi(params: any) {
  const base = '/api/app-api';
  return BasePost(base + '/system/i18n-missing/import-dev', params);
}

export default function() {
  // NO-TRANSLATION
  const { setAppLanguage, setLanguage } = useModel('language');
  const [selectedOption, setSelectedOption] = useState('zh');
  const [selectedSwitch, setSelectedSwitch] = useState<any>(true);
  const [inputValue, setInputValue] = useState('');

  const { run: onSubmitRequest, loading: submitLoading } = useReq(
    i18nImportApi,
    {
      loadingDefault: false,
      manual: true,
      onSuccess: () => {
        toast.success('操作成功');
      },
    },
  );

  function formatObjectToArray(
    obj: Record<string, string>,
  ): { title: string; translation: string }[] {
    const result: { title: string; translation: string }[] = [];
    // eslint-disable-next-line guard-for-in
    for (const key in obj) {
      result.push({ title: key, translation: obj[key] });
    }
    return result;
  }

  const renderLanguage = useMemo(() => {
    switch (selectedOption) {
      case 'zh':
        return formatObjectToArray(zh.translation);
      case 'en':
        return formatObjectToArray(en.translation);
      case 'jp':
        return formatObjectToArray(jp.translation);
      case 'de':
        return formatObjectToArray(deTranslation.translation);
      case 'es':
        return formatObjectToArray(esTranslation.translation);
      case 'fr':
        return formatObjectToArray(frTranslation.translation);
      case 'hi':
        return formatObjectToArray(hiTranslation.translation);
      case 'it':
        return formatObjectToArray(itTranslation.translation);
    }
    return [];
  }, [selectedOption]);

  const keys = Object.keys(en.translation).join();

  const searchFormatArray = useMemo(() => {
    if (inputValue) {
      return renderLanguage.filter((item) =>
        (selectedSwitch ? item.title : item.translation)
          .toLowerCase()
          .includes(inputValue.toLowerCase()),
      );
    }
    return renderLanguage;
  }, [inputValue, renderLanguage, selectedSwitch]);

  const { i18n, t } = useTranslation();
  // 验证
  const [username, setUsername] = useState('');
  const [isTrueProsy, setIsTrueProsy] = useState(false);

  const useKeyBySourse = (isSourse: boolean) => {
    if (isSourse) {
      const { translation } = enTranslation || {}; //本地的语言json
      let resultkeys: any = {};
      Object.entries(translation).forEach(([key, value]) => {
        resultkeys[key] = key;
      });
      setLanguage('sourseKey');
      i18n.addResourceBundle('sourseKey', 'translation', resultkeys);
      i18n.changeLanguage('sourseKey'); // 确保新的语言设置生效
      requestAnimationFrame(() => {
        history.replace('/');
      });
    } else {
      setAppLanguage(defaultLocale);
    }
  };

  const [apiUrl, setApiUrl] = useState(`http://localhost:${httpPort}`);
  const [soketUrl, setSoketUrl] = useState(`ws://localhost:${socketPort}`);
  const [imSoketUrl, setImSoketUrl] = useState(
    `ws://localhost:${imSocketPort}`,
  );

  const [apiUrlHistory, setApiUrlHistory] = useState<string[]>([]);
  const [soketUrlHistory, setSoketUrlHistory] = useState<string[]>([]);
  const [imSoketUrlHistory, setImSoketUrlHistory] = useState<string[]>([]);
  // 同步netEnum 数据
  const [iPList, setIPlist] = useState<any[]>([]);
  const [choiceIP, setChoiceIP] = useState('localhost');

  // 从 localStorage 初始化历史记录
  useEffect(() => {
    // const apiHistory = JSON.parse(
    //   localStorage.getItem('apiUrlHistory') || '[]',
    // );
    // const soketHistory = JSON.parse(
    //   localStorage.getItem('soketUrlHistory') || '[]',
    // );

    // const imSoketUrlHistory = JSON.parse(
    //   localStorage.getItem('imSoketUrlHistory') || '[]',
    // );

    // setApiUrlHistory(apiHistory);
    // setSoketUrlHistory(soketHistory);
    // setImSoketUrlHistory(imSoketUrlHistory);
    setIPlist(['localhost', ...devList]);
  }, []);

  // 更新历史记录并存入 localStorage
  const updateHistory = (
    key: string,
    value: string,
    history: string[],
    setHistory: any,
  ) => {
    if (!value || history.includes(value)) return;

    const updatedHistory = [value, ...history].slice(0, 10); // 只保留最近10条记录
    localStorage.setItem(key, JSON.stringify(updatedHistory));
    setHistory(updatedHistory);
  };
  // 初始化数据
  useMount(() => {
    const apiUrl = localStorage.getItem('window.apiUrl');
    const socketUrl = localStorage.getItem('window.soketUrl');
    const imSocketUrl = localStorage.getItem('window.imSoketUrl');
    if (apiUrl) {
      setApiUrl(apiUrl);
      setChoiceIP(apiUrl?.match?.(/^(http|https):\/\/([^/:]+)/i)?.[2]);
      setIsTrueProsy(true);
    }
    if (socketUrl) {
      setSoketUrl('ws://' + socketUrl.split('/')[2]);
      setIsTrueProsy(true);
    }
    if (imSocketUrl) {
      setImSoketUrl('ws://' + imSocketUrl.split('/')[2]);
      setIsTrueProsy(true);
    }
  });
  // 账户确认
  const handleLoginProsy = () => {
    if (username === '1') {
      setIsTrueProsy(true);
    } else {
      toast.error(t('账号/密码错误'));
    }
  };

  const { languageList } = useModel('language');
  // 使用自定义hook从后台获取指定语言的配置数据
  const { loading, runAsync: getSysJsonReq } = useReq(getLangJsonApi, {
    cacheKey: CacheEnum.APP_ARRANGEMENT_I18N,
    manual: true,
    // 处理后台返回的语言配置数据
    formatResult: (res: any) => {
      return res?.data?.translation ?? {};
    },
  });
  const handlerExport = async () => {
    // 本地所有语言json
    const localLanguageJson: any = {};
    const languageJson: any = await getSysJsonReq({ typeCode: 'zh-TW' });
    await Promise.all(languageList.map((l: any) => {
      import(`@/locales/${l.code}.json`).then(res => {
        localLanguageJson[l.code] = res.translation;
      });
    }));
    import('@/locales/zh-TW.json').then(res => {
      const noTranslation: any[] = [];
      Object.keys(res.translation).forEach((key) => {
        if (!languageJson[key]) {
          noTranslation.push(key);
        }
      });
      const xlsData = [
        {
          sheet: 'sheet1',
          columns: [
            { label: '原文', value: 'original' },
          ],
          content: noTranslation.map(item => {
            const res = {
              original: item,
            };
            languageList.forEach(l => {
              res[l.code] = localLanguageJson[l.code][item] || '';
            });
            return res;
          }),
        }];
      languageList.forEach(l => {
        xlsData[0].columns.push({ label: l?.code, value: l?.code });
      });
      exportExcel(xlsData, 'front');
    });
  };

  const handlerExport1 = async () => {
    const languageJson: any = await getSysJsonReq({ typeCode: 'zh-TW' });
    import('@/locales/zh-TW.json').then(res => {
      const noTranslation = [];
      Object.keys(languageJson).forEach((key) => {
        if (!res.translation[key]) {
          noTranslation.push(key);
        }
      });
      FormatUtils.copyText(JSON.stringify(noTranslation));

    });
  };

  return (
    <>

      <BaseTopNav title={t('国际化工具')} />
      {!isTrueProsy && (
        <div className="px-4 w-full flex flex-wrap gap-y-3">
          <BaseInput
            value={username}
            isShowClear={false}
            onChange={(e) => setUsername(e.target.value)}
            placeholder={t('请输入账号')}
          />
          <BaseInput isShowClear={false} placeholder={t('请输入密码')} />
          <div className="w-full flex justify-around">
            <Button onClick={handleLoginProsy}>{t('确认')}</Button>
            <Button onClick={() => history.replace('/login')}>{t('返回')}</Button>

          </div>
        </div>
      )}
      {
        isTrueProsy && <>
          <div className="w-full mt-6 px-4">
            <Button onClick={handlerExport} className="w-full">导出后端没有的语言xlsx</Button>
          </div>
          <div className="w-full mt-6 px-4">
            <Button onClick={handlerExport1} className="w-full">复制后端多的语言</Button>
          </div>
          {/*修改18*/}
          <div className="w-full mt-6 px-4">
            <UpDateI18Json />
          </div>
          <div className="w-full mt-6 px-4">
            <UpDateI18Xls />
          </div>
        </>
      }
      {isTrueProsy && (
        <div className="pt-3">
          <div className="pt-3">
            <div className="p-3 w-full">
              <Select
                className="w-full mb-2"
                placeholder="请选择代理 Url"
                selectedKeys={choiceIP ? [choiceIP] : undefined}
                popoverProps={{
                  classNames: {
                    content: 'bg-backgroundAuxiliaryColor text-foreground',
                  },
                }}
              >
                {iPList?.map?.((url) => (
                  <SelectItem
                    key={url}
                    value={url}
                    onPress={() => {
                      console.log(url, 'urlurlurl');
                      setChoiceIP(url);
                      setApiUrl(`http://${url}:${httpPort}`);
                      setSoketUrl(`ws://${url}:${socketPort}`);
                      setImSoketUrl(`ws://${url}:${imSocketPort}`);
                    }}
                    onClick={() => {
                      console.log(url, 'urlurlurl');
                      setChoiceIP(url);
                      setApiUrl(`http://${url}:${httpPort}`);
                      setSoketUrl(`ws://${url}:${socketPort}`);
                      setImSoketUrl(`ws://${url}:${imSocketPort}`);
                    }}
                  >
                    {url}
                  </SelectItem>
                ))}
              </Select>
              {/* API URL 输入框 */}
              <div className="flex">
                {/* <Select
                className="w-full"
                placeholder="请输入代理 Url"
                selectedKeys={apiUrl ? [apiUrl] : undefined}
                popoverProps={{
                  classNames: {
                    content: 'bg-backgroundAuxiliaryColor text-foreground',
                  },
                }}
              >
                {apiUrlHistory?.map?.((url) => (
                  <SelectItem
                    key={url}
                    value={url}
                    onPress={() => {
                      console.log(url, 'urlurlurl');
                      setApiUrl(url);
                    }}
                    onClick={() => {
                      console.log(url, 'urlurlurl');
                      setApiUrl(url);
                    }}
                  >
                    {url}
                  </SelectItem>
                ))}
              </Select> */}
                <BaseInput
                  value={apiUrl}
                  isShowClear={false}
                  onChange={(e) => setApiUrl(e.target.value)}
                  placeholder="请输入代理 Url"
                />
              </div>

              <div className="pt-3"></div>

              {/* Soket URL 输入框 */}
              <div className="flex">
                <BaseInput
                  value={soketUrl}
                  isShowClear={false}
                  onChange={(e) => setSoketUrl(e.target.value)}
                  placeholder="请输入代理 soket"
                />
              </div>

              {/* IM Soket URL 输入框 */}

              <div className="flex pt-3">
                <BaseInput
                  value={imSoketUrl}
                  isShowClear={false}
                  onChange={(e) => setImSoketUrl(e.target.value)}
                  placeholder="请输入代理 soket"
                />
              </div>

              <div className="pt-3"></div>
              <div>
                <Button
                  color="primary"
                  fullWidth
                  onClick={() => {
                    localStorage.clear();
                    // 保存到 localStorage 并刷新页面
                    localStorage.setItem('window.apiUrl', apiUrl);
                    localStorage.setItem(
                      'window.soketUrl',
                      soketUrl + '/app-websocket?Authorization=',
                    );
                    localStorage.setItem(
                      'window.imSoketUrl',
                      imSoketUrl + '/im-websocket?Authorization=',
                    );
                    history.replace('/login');
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  }}
                >
                  <div className="text-xs">设置代理</div>
                </Button>
                <div className="pt-3"></div>

                <Button
                  fullWidth
                  onClick={() => {
                    localStorage.removeItem('window.apiUrl');
                    localStorage.removeItem('window.soketUrl');
                    localStorage.removeItem('window.imSoketUrl');

                    history.replace('/login');
                    setIsTrueProsy(false);
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  }}
                >
                  <div className="text-xs">清空代理配置</div>
                </Button>
              </div>
            </div>
          </div>

          <div className="w-full justify-between items-center px-3">
            <span>选择的语言 {selectedOption}</span>
            <Select
              className="w-full mt-2"
              placeholder="请选择语言"
              selectedKeys={[selectedOption]}
              // onChange={(v) => setSelectedOption(v)}
              classNames={{
                trigger: `bg-backgroundAuxiliaryColor rounded-md h-[50px]`,
              }}
              popoverProps={{
                classNames: {
                  content: 'bg-backgroundAuxiliaryColor text-foreground',
                },
              }}
            >
              {[
                { label: '中文', value: 'zh' },
                { label: '英文', value: 'en' },
                { label: '日本', value: 'jp' },
                { label: '德国', value: 'de' },
                { label: '西班牙', value: 'es' },
                { label: '法国', value: 'fr' },
                { label: '印度', value: 'hi' },
                { label: '意大利', value: 'it' },
              ].map((item) => (
                <SelectItem
                  key={item.value}
                  value={item.value}
                  onClick={() => {
                    setSelectedOption(item.value);
                  }}
                >
                  {item.label}
                </SelectItem>
              ))}
            </Select>
          </div>

          <div className="justify-center py-6 px-2">
            <span>打开原本的key显示所有页面 (点击会返回首页)</span>
            <div className="pt-5">
              <Switch
                onChange={(e) => {
                  if (e) {
                    useKeyBySourse(e.target.checked);
                  }
                }}
              />
            </div>
          </div>

          <div className="justify-center py-6 px-2">
            <span>发送 JSON 到后端</span>
            <div className="pt-5">
              <Button
                spinner={<LoadingSvg />}
                onClick={() => {
                  onSubmitRequest({
                    typeCode: selectedOption,
                    catalog: 1,
                    current: keys,
                  });
                }}
                className={`text-white rounded-md`}
                isLoading={submitLoading}
              >
                <div className="text-xs">
                  <div>发送 {selectedOption} json 到后端</div>
                </div>
              </Button>
            </div>
          </div>

          <div className="p-3 w-full">
            <BaseInput
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="请输入搜索关键字"
              startContent={
                <BasilSearchSolid className="text-iconFontColor text-xl" />
              }
            />
          </div>

          {searchFormatArray.length === 0 && <div className="pt-6">空</div>}
          {searchFormatArray?.map?.((item) => (
            <div
              key={item.title}
              className="flex justify-start items-center p-2"
            >
              <span
                className="cursor-pointer"
                onClick={() => FormatUtils.copyText(item.title)}
              >
                {item.title}
              </span>
              <span className="text-red-700 mx-2 font-bold">:</span>
              <span
                className="cursor-pointer"
                onClick={() => FormatUtils.copyText(item.translation)}
              >
                {item.translation}
              </span>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
