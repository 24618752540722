import { useEffect, useRef, useState } from 'react';
import { toast } from '@/layouts/components/ToastMessage';

export default function UpDateI18Json() {
  const uploadRef = useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = useState(false); // 上传状态

  useEffect(() => {
    uploadRef.current?.addEventListener('change', onChange as any);
    return () => {
      uploadRef.current?.removeEventListener('change', onChange as any);
    };
  }, []);

  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files || files.length === 0) return;

    setIsUploading(true); // 开始上传

    try {
      // 检查所有文件类型
      for (const file of Array.from(files)) {
        if (!file.name.endsWith('.json')) {
          throw new Error('仅支持 JSON 文件');
        }
      }

      // 逐个上传文件
      for (const file of Array.from(files)) {
        const formData = new FormData();
        formData.append('file', file); // 使用 'file' 作为字段名

        const response = await fetch('/api/update-i18n', {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          const error = await response.json();
          throw new Error(error.error || `文件 ${file.name} 上传失败`);
        }
      }

      toast.success(`${files.length} 个语言文件更新成功！`);
    } catch (err) {
      toast.error(err instanceof Error ? err.message : '未知错误');
    } finally {
      setIsUploading(false); // 结束上传
      event.target.value = ''; // 清空选择器
    }
  };

  return (
    <div>
      <input
        type="file"
        className="hidden"
        accept=".json"
        ref={uploadRef}
        multiple // 支持多选文件
        disabled={isUploading} // 上传时禁用选择器
      />
      <button
        type="button"
        className="mainColorButton !bg-primary !h-[40px] !p-0"
        onClick={() => {
          uploadRef.current?.click();
        }}
        disabled={isUploading} // 上传时禁用按钮
      >
        {isUploading ? '上传中...' : '导入覆盖本地语言.json文件'}
      </button>
      <span className="text-auxiliaryTextColor text-xs">(*支持多个文件上传)</span>
    </div>
  );
}